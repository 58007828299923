<template lang="html">
  <a
    v-if="!(disabled || blocked)"
    :class="$style.action"
    href="#"
    @click.prevent="$emit('click')"
  >
    <slot />
  </a>
  <span v-else :class="{[$style.action]: true, [$style.disabled]: disabled, [$style.blocked]: blocked}">
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    blocked: Boolean
  }
}
</script>

<style lang="scss" module>
.action:not(:first-child) {
  margin-left: 0.5em;
}

.disabled {
  opacity: 0.5;
}

.blocked:not(:disabled) {
  cursor: not-allowed;
}
</style>
